import React, { useState } from "react"
import Layout from "../components/Layouts/Layout/Layout"
import Seo from "../components/seo"
import componentFactory from "../utils/componentFactory"
import ContentBuilder from "../components/sections/ContentBuilder"
import TitleBlock from "../components/Blocks/TitleBlock/TitleBlock"
import JobOfferFormBlock from "../components/Blocks/JobOfferFormBlock/JobOfferFormBlock"
import LocationBlock from "../components/Blocks/LocationBlock/LocationBlock"
import * as jobOfferTemplateStyles from "./jobOffer-template.module.scss"

const JobOfferTemplate = ({ pageContext }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [agencies, setAgencies] = useState(false)
  const page = pageContext.page.attributes
  const item = pageContext.item.attributes
  const id = pageContext.item.id
  const locationIcon = pageContext.design.locationIcon
  const offerImage = pageContext.design.viateleaseLogo
  const components = page.components.map(i => componentFactory(i, pageContext))
  const jobOffer = pageContext.jobOffers.filter(
    i => i.id === pageContext.item.id
  )[0]
  return (
    <>
      <Layout>
        <TitleBlock title={pageContext.item.attributes.name} link={true} />
        <section className={jobOfferTemplateStyles.meta}>
          <div className="container sm-container loadin">
            <div className={jobOfferTemplateStyles.tags}>
              <LocationBlock
                text={item.tagLabel}
                locationIcon={true}
                type="notClicked"
              />
              <LocationBlock text={item.contractType} type="notClicked" />
            </div>
            <div className={jobOfferTemplateStyles.dates}>
              <div>{item.disponibilite}</div>
            </div>
          </div>
        </section>
        {item.content1.map((content1, index) => {
          return content1.html ? (
            <ContentBuilder
              key={index}
              html={content1.html}
              color={content1.color}
              background_image={content1.background_image}
            />
          ) : (
            <></>
          )
        })}
        <div
          className={`${jobOfferTemplateStyles.buttonContainer} ${"loadin"}`}
        >
          <button
            className="button primary"
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Postuler
          </button>
        </div>
        <JobOfferFormBlock
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={id}
          jobOffer={jobOffer}
          locationIcon={locationIcon}
          offerImage={offerImage}
        />
        {components}
      </Layout>
    </>
  )
}
export const Head = ({ pageContext }) => {
  const agenciesData = pageContext.item.attributes.agency.data.map(
    item => item.attributes.name
  )
  const agencies = agenciesData.join(", ")

  return (
    <Seo
      title={
        "Offre d'emploi : " +
        [
          agencies,
          pageContext.item.attributes.name,
          pageContext.item.attributes.contractType,
        ].join(" - ")
      }
      description={pageContext.item.attributes.description}
    />
  )
}
export default JobOfferTemplate
