import React, { useRef, useState, useEffect } from "react"
import * as JobOfferFormStyle from "./JobOfferFormBlock.module.scss"
import * as JobOfferBlockStyle from "../JobOfferBlock/JobOfferBlock.module.scss"
import LocationBlock from "../LocationBlock/LocationBlock"
import imgPuce from "../../../images/puce.svg"
import imgCroixPetite from "../../../images/croix-petite.svg"
import imgPlus from "../../../images/plus.svg"
import imgCroixGrande from "../../../images/croix-grande.svg"
import fleche from "../../../images/fleche.svg"

const { formatDateNumeric } = require("../../../utils/formatDate")
const JobOfferFormBlock = ({
  isOpen,
  setIsOpen,
  jobOffer,
  offerImage,
  locationIcon,
}) => {
  const Modal = useRef()
  const [firstName, setFirstName] = useState("")
  const [secondName, setSecondName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [filesCV, setFilesCV] = useState([])
  const [filesLM, setFilesLM] = useState([])
  const [ConfirmForm, setConfirmForm] = useState(false)

  useEffect(() => {
    if (isOpen) {
      Modal.current.showModal()
    } else {
      Modal.current.close()
    }
  }, [isOpen])

  const handleFileChange1 = event => {
    for (let i = 0; i < event.target.files.length; i++) {
      setFilesCV(oldFiles => {
        const newFiles = [...oldFiles]
        newFiles.push(event.target.files[i])
        return newFiles
      })
    }
  }

  const handleFileChange2 = event => {
    for (let i = 0; i < event.target.files.length; i++) {
      setFilesLM(oldFiles => {
        const newFiles = [...oldFiles]
        newFiles.push(event.target.files[i])
        return newFiles
      })
    }
  }
  const handleDeleteCVFiles = index => {
    setFilesCV(oldFiles => {
      return oldFiles.filter((f, i) => {
        return index !== i
      })
    })
  }
  const handleDeleteLMFiles = index => {
    setFilesLM(oldFiles => {
      return oldFiles.filter((f, i) => {
        return index !== i
      })
    })
  }

  const handleSubmit = async event => {
    setConfirmForm(true)
    event.preventDefault()
    try {
      const formdata1 = new FormData()
      let fileCVIds
      if (filesCV.length > 0) {
        for (let j = 0; j < filesCV.length; j++) {
          formdata1.append("files", filesCV[j])
        }
        const res1 = await fetch(process.env.GATSBY_API_URL + "/api/upload", {
          method: "POST",
          body: formdata1,
        })
        const resData1 = await res1.json()
        fileCVIds = resData1.map(file => file)
      }

      const formdata2 = new FormData()
      let fileLMIds
      if (filesLM.length > 0) {
        for (let k = 0; k < filesLM.length; k++) {
          formdata2.append("files", filesLM[k])
        }
        const res2 = await fetch(process.env.GATSBY_API_URL + "/api/upload", {
          method: "POST",
          body: formdata2,
        })
        const resData2 = await res2.json()
        fileLMIds = resData2.map(file => file)
      }

      const data = {
        firstName: firstName,
        secondName: secondName,
        phoneNumber: phoneNumber,
        email: email,
        attachmentsCV: fileCVIds,
        attachmentsLM: fileLMIds,
        jobOfferName: jobOffer.attributes.name,
      }

      await fetch(process.env.GATSBY_API_URL + "/api/job-offer-form-posts", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ data }),
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      className={`${JobOfferFormStyle.ContactForm} ${"container lg-container"}`}
    >
      <dialog ref={Modal} onClose={() => setIsOpen(false)}>
        {ConfirmForm ? (
          <>
            <div className={JobOfferFormStyle.ModalCross}>
              <button
                type="button"
                className={JobOfferFormStyle.Cross}
                onClick={() => setIsOpen(false)}
              >
                {" "}
                <img src={imgCroixGrande} alt="icone croix" />
              </button>
            </div>
            <div className={JobOfferFormStyle.Confirmed}>
              <h2>Votre demande a bien été envoyée.</h2>
              <p className={JobOfferFormStyle.MessageText}>
                Elle sera traitée dans les plus brefs délais.
              </p>
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className={JobOfferFormStyle.ModalCross}>
              <button
                className={JobOfferFormStyle.Cross}
                type="button"
                onClick={() => setIsOpen(false)}
              >
                {" "}
                <img src={imgCroixGrande} alt="icone croix" />
              </button>
            </div>
            <div>
              <div className={JobOfferFormStyle.title}>
                <h2 className="big">Vous souhaitez candidater au poste</h2>
              </div>

              <div className={JobOfferFormStyle.jobOfferBlock}>
                <section className={JobOfferBlockStyle.JobOfferBlock}>
                  <img
                    className={JobOfferBlockStyle.logo}
                    src={offerImage.data.attributes.url}
                    alt={offerImage.data.attributes.alternativeText}
                    width={offerImage.data.attributes.width}
                    height={offerImage.data.attributes.height}
                  />
                  <div className={JobOfferBlockStyle.title}>
                    {jobOffer.attributes.name}
                  </div>

                  <LocationBlock
                    className={JobOfferBlockStyle.tag}
                    text={jobOffer.attributes.tagLabel}
                    locationIcon={locationIcon}
                    type="notClicked"
                  />

                  <div className={JobOfferBlockStyle.date}>
                    <p style={{ fontWeight: "bold" }}>
                      {formatDateNumeric(jobOffer.attributes.publishedAt)}
                    </p>
                  </div>

                  <div className={JobOfferBlockStyle.description}>
                    <p className={JobOfferBlockStyle.descriptionDetail}>
                      {jobOffer.attributes.description}
                    </p>
                  </div>
                  <div className={JobOfferBlockStyle.linkContainer}>
                    <a
                      className={JobOfferBlockStyle.link}
                      jobOffer={jobOffer.id}
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      Voir l'offre
                      <img
                        src={fleche}
                        alt="icone flèche"
                        className={JobOfferFormStyle.arrow}
                      />
                    </a>
                  </div>
                </section>
              </div>
              <div className={JobOfferFormStyle.Content}>
                <legend>Informations</legend>
                <input
                  type="text"
                  placeholder="Nom"
                  name="Nom"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Prenom"
                  name="Prenom"
                  value={secondName}
                  onChange={e => setSecondName(e.target.value)}
                  required
                />
                <legend>Contact </legend>
                <input
                  type="tel"
                  placeholder="Tel"
                  name="Telephone"
                  pattern="[0-9]{10}"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="Email"
                  value={email}
                  pattern={"[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-zA-Z]{2,}"}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="cv">CV</label>

                <div>
                  {filesCV.map((f, i) => {
                    return (
                      <div key={i} className={JobOfferFormStyle.uploadedRow}>
                        <div className={JobOfferFormStyle.filesName}>
                          <div>
                            <img src={imgPuce} alt="icone puce" />
                          </div>
                          <div>{f.name}</div>
                        </div>
                        <div className={JobOfferFormStyle.deleteBlock}>
                          <div>
                            <img src={imgCroixPetite} alt="icone croix" />
                          </div>
                          <p
                            type="button"
                            className={JobOfferFormStyle.deleteButton}
                            onClick={() => handleDeleteCVFiles(i)}
                          >
                            Supprimer
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div
                  role="presentation"
                  className={JobOfferFormStyle.myform}
                  onClick={() => document.querySelector(".inputFieldd").click()}
                >
                  <input
                    id="cv"
                    name="CV"
                    className="inputFieldd"
                    type="file"
                    multiple
                    accept="image/*,.pdf"
                    hidden
                    onChange={handleFileChange1}
                    required
                  />

                  <>
                    <img
                      src={imgPlus}
                      alt="icone plus"
                      className={JobOfferFormStyle.addFile}
                    />
                    <p className={JobOfferFormStyle.text}>Choisir un fichier</p>
                  </>
                </div>
                <label htmlFor="lm">Lettre de motivation</label>
                <div>
                  {filesLM.map((f, i) => {
                    return (
                      <div key={i} className={JobOfferFormStyle.uploadedRow}>
                        <div className={JobOfferFormStyle.filesName}>
                          <div>
                            {" "}
                            <img src={imgPuce} alt="icone puce" />
                          </div>
                          <div>{f.name}</div>
                        </div>
                        <div className={JobOfferFormStyle.deleteBlock}>
                          <div>
                            <img src={imgCroixPetite} alt="icone croix" />
                          </div>
                          <p
                            type="button"
                            className={JobOfferFormStyle.deleteButton}
                            onClick={() => handleDeleteLMFiles(i)}
                          >
                            Supprimer
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div
                  role="presentation"
                  className={JobOfferFormStyle.myform}
                  onClick={() =>
                    document.querySelector(".inputFielddD").click()
                  }
                >
                  <input
                    id="lm"
                    name="LM"
                    className="inputFielddD"
                    type="file"
                    multiple
                    accept="image/*,.pdf"
                    hidden
                    onChange={handleFileChange2}
                  />

                  <>
                    <img
                      src={imgPlus}
                      alt="icone plus"
                      className={JobOfferFormStyle.addFile}
                    />
                    <p className={JobOfferFormStyle.text}>Choisir un fichier</p>
                  </>
                </div>
                <div className={JobOfferFormStyle.myButton}>
                  <button
                    className="button primary"
                    type="submit"
                    value="Submit"
                  >
                    Envoyer ma candidature
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </dialog>
    </div>
  )
}

export default JobOfferFormBlock
