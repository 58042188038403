// extracted by mini-css-extract-plugin
export var Confirmed = "JobOfferFormBlock-module--Confirmed--eb3ca";
export var ContactForm = "JobOfferFormBlock-module--ContactForm--cd1e6";
export var Content = "JobOfferFormBlock-module--Content--ed4c9";
export var Cross = "JobOfferFormBlock-module--Cross--ab460";
export var MessageText = "JobOfferFormBlock-module--MessageText--79b45";
export var ModalCross = "JobOfferFormBlock-module--ModalCross--bac8a";
export var addFile = "JobOfferFormBlock-module--addFile--7e2ea";
export var arrow = "JobOfferFormBlock-module--arrow--3c9d4";
export var attachmentIcon = "JobOfferFormBlock-module--attachmentIcon--06a57";
export var customerField = "JobOfferFormBlock-module--customerField--40b28";
export var deleteBlock = "JobOfferFormBlock-module--deleteBlock--faf0e";
export var deleteButton = "JobOfferFormBlock-module--deleteButton--3450c";
export var deleteIcon = "JobOfferFormBlock-module--deleteIcon--38369";
export var filesName = "JobOfferFormBlock-module--filesName--96dca";
export var jobOfferBlock = "JobOfferFormBlock-module--jobOfferBlock--d8fc4";
export var myButton = "JobOfferFormBlock-module--myButton--55327";
export var myform = "JobOfferFormBlock-module--myform--fe48f";
export var text = "JobOfferFormBlock-module--text--b3478";
export var title = "JobOfferFormBlock-module--title--299df";
export var uploadedRow = "JobOfferFormBlock-module--uploadedRow--3d05f";
export var validationIcon = "JobOfferFormBlock-module--validationIcon--ad3d9";